import React,{useState} from "react";

import { useStore } from "../store/useStore";

import { config } from "../config";
import { BrowserRouter as Router, Switch, Route,Link } from "react-router-dom";
import { apiClient } from "../apiCall";
import { isDOMComponentElement } from "react-dom/test-utils";
import { getDefaultNormalizer } from "@testing-library/react";
import Errors from './Errors';
import userEvent from "@testing-library/user-event";
import editlogo from '../logos/edit.svg';


export const DefaultFileData = {
	edit:false,
    name:null,
    ean:null,
    description:null,
    id:null,
    folders:[],
    errors:[]
  
};
export default function FileInfo(props) {
	const { state, dispatch } = useStore();
    const { common,basket,companies,user } = state;
	
    const apiCall = apiClient(state, dispatch);

     const file=common.openfile;


     const [FileData, setFileData] = useState({
        ...DefaultFileData
       
		
    });

    const  editFile=(e)=>{

        let data={...FileData};

        let url="file/"+FileData.id+"/edit";
        apiCall.ADD(url, data,function(status,err_msgs=null){

            if(status==400){

                setFileData({...FileData,errors:err_msgs})
            }else if(status==200){

                dispatch({ type: "OPENFILEINFO", file: null });
                apiCall.GET("companies");
            }

        }).then(() => {
            //apiCall.GET('destinations');
            
        });


    };
    const removeSelectedFolder=(index)=>{

       
        let f=[...FileData.folders];
        f.splice(index,1);
        setFileData({...FileData,folders:f})
    }
    const folderSelector=(folders)=>{
console.log("sleccted folders",folders);
        return folders.map((folder,index) =>
     
           <span key={folder.id} onClick={() => removeSelectedFolder(index)}>{folder.name}</span>
           
         );
     
     };
     const removeFilePermanently=()=>{


        let url="file/"+file.id+"/remove";
        //dispatch({ type: "BASKET_REMOVE",file:file });
       apiCall.ADD(url, {id:file.id},function(status,err_msgs=null,response=null){

       if(status==400){

           //setFolderData({...FolderData,errors:err_msgs})
       }else if(status==200){

        dispatch({ type: "OPENFILEINFO", file: null });
             apiCall.GET("basket");
            apiCall.GET("companies");
          //dispatch({ type: "BASKET_LOADED",payload:{data:response.data} });
       }

   }).then(() => {
       //apiCall.GET('destinations');
       
   });





     }
     const close=(e)=>{
        dispatch({ type: "OPENFILEINFO", file: null });

     }
     const removeFile=(item)=>{
      
        // setfolderbrowserState({open:itemid});
        //dispatch({ type: "OPENFILEINFO", file: item });

             let url="basket/remove";
             dispatch({ type: "BASKET_REMOVE",file:file });
            apiCall.ADD(url, {id:file.id},function(status,err_msgs=null,response=null){

            if(status==400){
                
                //setFolderData({...FolderData,errors:err_msgs})
            }else if(status==200){
                
               dispatch({ type: "BASKET_LOADED",payload:{data:response.data} });
            }

        }).then(() => {
            //apiCall.GET('destinations');
            
        });




   };
   const isInBasket=(id)=>{

        console.log("seaarc",id,basket);
         if(basket.items){

            for(var i=0;i<basket.items.content.length;i++){

                if(basket.items.content[i].files_id==id){
                    console.log("OLI BA");
                    return true;
                }
            }
         }  
         
         return false;
   };

     const addFile=(item)=>{
      
            // setfolderbrowserState({open:itemid});
            //dispatch({ type: "OPENFILEINFO", file: item });

                 let url="basket/add";
                 dispatch({ type: "BASKET_ADDING",file:file });
                apiCall.ADD(url, {id:file.id},function(status,err_msgs=null,response=null){
    
                if(status==400){
                    
                    //setFolderData({...FolderData,errors:err_msgs})
                }else if(status==200){
                    
                   dispatch({ type: "BASKET_LOADED",payload:{data:response.data} });
                }
    
            }).then(() => {
                //apiCall.GET('destinations');
                
            });




       };
       const startEditing=(e)=>{


        setFileData({...file,edit:true});

       };

       const changeText=(e)=>{


        setFileData({...FileData,[e.currentTarget.name]:e.currentTarget.value});


       };
       const getFlat=(folders,t)=>{

        for(var i=0;i<folders.length;i++){

            t.push({folder:folders[i]});

            if(folders[i].children.length!=0){

                getFlat(folders[i].children,t);
            }
        }
        return t;
};

const getOptionsNav=(items)=>{

    let t=[];
   const flat= getFlat(items,t);
            return flat.map((item) =>

              


             <option key={item.folder.id} value={item.folder.id}>{"-".repeat(item.folder.depth)}{item.folder.name}</option>
           
     );

  };
       const getFolderSelectors=(companies)=>{

        const selectors=companies.map((item)=>
           
          <select onChange={addFolder}><option value='0'>--  {item.name}   --</option>{getOptionsNav(item.folders)}</select>
        );
 
         return (<div className="folder-selectors">{selectors}</div>);
       };

       const addFolder=(e)=>{


        let selectedindex = e.nativeEvent.target.selectedIndex;
        let label = e.nativeEvent.target[selectedindex].text;


        if(e.currentTarget.value!=="0"){
      // FileData=[... mod.folders,{id:e.currentTarget.value,name:label}];
       
        let nf=[...FileData.folders,{id:e.currentTarget.value,name:label}]
        setFileData({...FileData,"folders":nf});

        }

       };
       if(FileData.edit && FileData.id===file.id && user.role===1){

console.log("FINEDIII",FileData);
        return (<div className="side-editor side-fileinfo">
        <img src={config.apiUrl+""+file.thumb} />
       {FileData.errors?<Errors msgs={FileData.errors}/>:null}
        <div className="inner">
        
            <label>Nimi
            <input type="text" name="name" value={FileData.name} onChange={changeText} />
            </label>
            <label>Ean
            <input type="text" name="ean" value={FileData.ean} onChange={changeText} />
            </label>
            <label>Kuvaus
            <textarea name="description" value={FileData.description} onChange={changeText} ></textarea>
            </label>
            <h5>Valitse kansiot</h5>
            <div>{getFolderSelectors(companies.companies)}</div>
            <div className="selected-folders">{folderSelector(FileData.folders)}</div>

           
            <div className="button-holder">
                    <button onClick={()=>editFile(file)}>Tallenna</button> 
                </div>
                <div className="button-holder close">
                    <button onClick={()=>close()}>Peruuta</button> 
                </div>
                <div className="button-holder remove">
                     <button disabled={basket.adding} onClick={()=>removeFilePermanently(FileData)}>Tuhoa tiedosto</button> 
                 </div>
        </div>
</div>);




       }


    return (<div className="side-editor side-fileinfo">
            <img src={config.apiUrl+""+file.thumb} />
            <div className="inner">
                <h2>{file.name} {user.role===1?<span onClick={startEditing}><img src={editlogo}/></span>:null}</h2>
                {file.ean?<p className="ean">Ean: {file.ean}</p>:null}
                
                {file.description?<p className="description">{file.description}</p>:null}
                {!isInBasket(file.id)?
                <div className="button-holder">
                    <button disabled={basket.adding} onClick={()=>addFile(file)}>Lisää pakettiin</button> 
                </div>
                
                :<div className="button-holder remove">
                <button disabled={basket.adding} onClick={()=>removeFile(file)}>Poista paketista</button> 
            </div>}
            <div className="button-holder close">
                    <button onClick={()=>close()}>Sulje</button> 
                </div>
            </div>
    </div>);
   
}