import React, { createContext, useReducer, useContext } from "react";
import PropTypes from "prop-types";
import { userInitialState, userReducer } from "./user";
import { companiesInitialState, companiesReducer } from "./companies";
import { basketInitialState, basketReducer } from "./basket";

import { commonInitialState, commonReducer } from "./common";


import { usersInitialState, usersReducer } from "./users";


const initialState = {
	
	user: userInitialState,
    companies:companiesInitialState,
	basket:basketInitialState,
	common:commonInitialState,
	users:usersInitialState
};

const StoreContext = createContext(initialState);


const reducer = (state, action) => {
	const {
        user,
        companies,
		basket,
		common,
		users
	} = state;

	console.log("reducer action: ", action.type);

	const currentState = {
        user: userReducer(user, action),
        companies:companiesReducer(companies, action),
		basket:basketReducer(basket,action),
		common:commonReducer(common,action),
		users:usersReducer(users,action)
	};

	return currentState;
};

export const StoreProvider = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, initialState);
	return (
		<StoreContext.Provider value={{ state, dispatch }}>
			{children}
		</StoreContext.Provider>
	);
};

StoreProvider.propTypes = {
	children: PropTypes.node,
};

export const useStore = (store) => {
	const { state, dispatch } = useContext(StoreContext);
	return { state, dispatch };
};
