import {React,useEffect,useState} from "react";

import { useStore } from "../store/useStore";
import axios from "axios";
import { config } from "../config";
import { BrowserRouter as Router, Switch, Route,Link } from "react-router-dom";
import { apiClient } from "../apiCall";


export const basketDefaultState = {
	
    open:false
};
export default function FullBasket(props) {
	const { state, dispatch } = useStore();

	const { basket,common } = state;
    const [basketState, setBasketState] = useState({
		...basketDefaultState
		
    });
    
    const apiCall = apiClient(state, dispatch);
    const fetchBasket = () => {
        // dispatch({ type: "FETCH_LOAD" });
     
         apiCall.GET("basket");
        };

    useEffect(() => {
        if(state.user.loggedIn && basket.loading==false && basket.items==null){
            
            
                fetchBasket();
        
       
        }
       
       
       }, [dispatch, state]);
       
       const openClose=(item)=>{
      
        setBasketState({open:!basketState.open});

      };
      const closeBasket=(item)=>{

        setBasketState({open:false});
      };

      const loadBasket=(item)=>{


            apiCall.GET("basketlink");

      };
      console.log("bastate",basketState);

       if(basket.loading==false && basket.items!=null){


        const openFile=(item)=>{
      
            // setfolderbrowserState({open:itemid});
            dispatch({ type: "OPENFILEINFO", file: item });
           };
       const items = basket.items.content.map((basketItem) =>
        
            

             <div className={common.openfile && basketItem.file.id==common.openfile.id?"item active":"item"} key={basketItem.file.id} onClick={()=>openFile(basketItem.file)}><div className="inner">
                <div className="image-holder"><div className="image"><img src={config.apiUrl+""+basketItem.file.thumb} /></div></div>  
                <div className="text"><h3>{basketItem.file.name}</h3></div></div></div>
        );




        return (
            <div className="folder-browswer">
                <div className="content"><div className="folder-header"></div>{items}</div>
               
            </div>)





       }

    return (<div className="basket loading">Ladataan</div>);
    
     
   
    
    
   
}