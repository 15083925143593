export const config = {
	isDev: process.env.NODE_ENV !== "production",
	// isDev: false,
	apiUrl://https://api.duellpankki.fi/public/api
		process.env.NODE_ENV === "production"
			? "https://api.duellpankki.com/public/api"
			: "https://api.duellpankki.com/public/api"

	
};
