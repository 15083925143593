import React,{useState,useEffect} from "react";

import { useStore } from "../store/useStore";

import { config } from "../config";
import { BrowserRouter as Router, Switch, Route,Link } from "react-router-dom";
import { apiClient } from "../apiCall";

import { commonFunctions } from "../commonFunctions";



import { isDOMComponentElement } from "react-dom/test-utils";
import { getDefaultNormalizer } from "@testing-library/react";
import Errors from './Errors';

export const defaultFolderData = {
	name: '',
    parent:0,
    folders:[],
    files:[],
    hide:false
  
};

export default function EditFolder(props) {
	const { state, dispatch } = useStore();

    const { companies,user } = state;
    

    const common = commonFunctions(state, dispatch);
    const folder=common.getFolder( props.match.params.folderId);
    console.log("SAIN PROPSINA",props.match.params.folderId,folder);

    const [FolderData, setFolderData] = useState({
        ...defaultFolderData,
        name:folder.name,
        parent:folder.folders_parent_id,
		folders:folder.children,
        files:folder.files,
        hide:false
    });

    useEffect(() => {
      // console.log("VALUE CHANGED!!!!!!!!!!!!!!!!!!!!!!!!!!!");

      const folder=common.getFolder( props.match.params.folderId);

      var newstate={ ...defaultFolderData,
      name:folder.name,
      parent:folder.folders_parent_id,
      folders:folder.children,
      files:folder.files,hide:false};
        
      setFolderData(
       newstate
      );
    


      }, [props.match.params.folderId]);
    
    console.log("saatee folder",FolderData)
   
    /*const [placeData, setPlaceData] = useState({
		...defaultValues,
		name: props.name ? props.name : '',
	});*/ 
    const apiCall = apiClient(state, dispatch);

    const getCompany = (companyId) => {

       
       for(var i=0;i<companies.companies.length;i++){
           
            if(companies.companies[i].id==companyId){

                return companies.companies[i];
            }
        } 

        return null;
       
      };

     
      
      let company=getCompany(props.match.params.companyId);
     
     
   //console.log("PROPSEI",props.match.params.companyId );
  


   // let companyData=getCorrectCompany(props.match.params.companyId);


   //console.log(companyData);
     const changeText=(e)=>{

      
        setFolderData({...FolderData,[e.currentTarget.name]:e.currentTarget.value})

     };
     const removeFolder=(e)=>{


        let url="folder/"+props.match.params.folderId+"/remove";
        apiCall.ADD(url, {},function(status,err_msgs=null){

            if(status==400){

               // setFolderData({...FolderData,errors:err_msgs})
            }else if(status==200){
                setFolderData({...FolderData,hide:true});
                apiCall.GET("companies");
            }

        }).then(() => {
            //apiCall.GET('destinations');
            
        });




     };
     const handleSubmit=(e)=>{
        let data={...FolderData};

        let url="companies/"+props.match.params.companyId+"/"+props.match.params.folderId+"/edit";
        apiCall.ADD(url, data,function(status,err_msgs=null){

            if(status==400){

                setFolderData({...FolderData,errors:err_msgs})
            }else if(status==200){

                apiCall.GET("companies");
            }

        }).then(() => {
            //apiCall.GET('destinations');
            
        });


     };
     const changeSelect=(e)=>{

      
        setFolderData({...FolderData,[e.currentTarget.name]:e.currentTarget.value})

     };
     const getFlat=(folders,t,exclude=null)=>{

            for(var i=0;i<folders.length;i++){

                console.log("exccclude",folders[i].id,exclude);
                if(parseInt(folders[i].id)!==exclude){


                t.push({folder:folders[i]});

                if(folders[i].children.length!=0){

                    getFlat(folders[i].children,t,exclude);
                }
            }
            }
            return t;
     };


     
     const getEmptyForDepth=(depth=0)=>{

        return "-".repeat(depth);
     };
     const getOptionsNav=(items)=>{

        let t=[];
       const flat= getFlat(items,t,parseInt(props.match.params.folderId));



       
        return flat.map((item) =>
   
                  


                 <option key={item.folder.id} value={item.folder.id}>{getEmptyForDepth(item.folder.depth)}{item.folder.name}</option>
               
         );

      };
      
    if(FolderData.hide){

        return null;
    }

    if(user.role!==1){

        return null;
    }
      const options=getOptionsNav(company.folders);
      console.log("FolderData",FolderData);
     const button_class="";
     console.log("VALUE",FolderData);   
    return (<div className="side-editor">
        <Errors msgs={FolderData.errors}/>
        <div className="inner">
        <label>Kansion nimi
            <input onChange={changeText} name='name' value={FolderData.name}/>
            </label>
        <select name="parent" value={FolderData.parent} onChange={changeSelect}>
            <option value="0">Päätaso</option>{options}</select>

            
            <div className="button-holder">
            <button onClick={handleSubmit} className={button_class}>Muokkaa</button>
            </div>
           
            {(FolderData.folders.length===0 && FolderData.files.length===0)?
            <div className="button-holder remove">

            <button onClick={removeFolder} >Tuhoa</button>
            </div>
            :null}
            
            </div></div>);
   
}