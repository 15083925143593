import React,{useState} from "react";

import { useStore } from "../store/useStore";
import axios from "axios";
import { config } from "../config";
import { BrowserRouter as Router, Switch, Route,Link } from "react-router-dom";
import userEvent from "@testing-library/user-event";
import editlogo from '../logos/edit.svg';
export const sideNavDefaultState = {
	
    opens:[]
};

export default function Sidenav(props) {
	const { state, dispatch } = useStore();

    const { companies,user } = state;
    
    const [sideNavData, setSideNavData] = useState({
		...sideNavDefaultState
		
    });
    console.log("FOLDER",props.match.params.folderId,sideNavData.opens);


   if(props.match.params.folderId && props.match.params.folderId!="addfolder" && props.match.params.folderId!="addfiles"){


       let myval=parseInt(props.match.params.folderId);



        if(!sideNavData.opens.some(val=>val===myval)){
            
            
            setSideNavData({opens:sideNavData.opens.concat(myval)});

        }
   }
    
    const getCompany = (companyId) => {

       
       for(var i=0;i<companies.companies.length;i++){
           
            if(companies.companies[i].id==companyId){

                return companies.companies[i];
            }
        } 

        return null;
       
      };
      const closeFileInfo=()=>{

        dispatch({ type: "OPENFILEINFO", file: null });


      };
      const openCloseClick=(item)=>{
        console.log("CLOSE=");
        
        console.log("ONKO AUKI?",item.id,sideNavData.opens,sideNavData.opens.some(val=>val==item.id))
      
       dispatch({ type: "OPENFILEINFO", file: null });
        if(!sideNavData.opens.some(val=>val==item.id)){
        
        
            setSideNavData({opens:sideNavData.opens.concat(item.id)});
    

           



        }else{

            var openit = [...sideNavData.opens];
            var index = openit.indexOf(item.id);
            if (index !== -1) {
                openit.splice(index, 1);
                setSideNavData({opens:openit});
            }

        }

      };
      const getIcon=(item)=>{

        if(sideNavData.opens.some(val=>val==item.id)){

            if(item.children.length!=0){

                return (<span className="open-close close" onClick={()=>openCloseClick(item)}>-</span>);

            }
        }else{

            
            if(item.children.length!=0){
                
                return (<span className="open-close open" onClick={()=>openCloseClick(item)}>+</span>);
           }
        }
        return (<span className="open-close empty"></span>);

      };
      const ifOpen=(item,level)=>{
//this.state.data.some(item => val.name === item.name); 
       
        if(sideNavData.opens.some(val=>val==item.id)){

            return (<ul>{getChildrenNav(item.children,level+1)}</ul>);

        }else{

            return null;
        }
       



      };

      const closeAll=()=>{

        dispatch({ type: "OPENFILEINFO", file: null });

      };
      const getChildrenNav=(items,level=0)=>{

        
        return items.map((item) =>(
                
              
                <li key={item.id}><span className="adm">{getIcon(item)}<Link onClick={closeAll} to={'/company/'+item.companies_id+'/'+item.id}>{item.name}</Link>
                {user.role===1?<span className='admin-tools'><Link  to={'/company/'+item.companies_id+'/'+item.id+"/editfolder"}><img src={editlogo}/></Link></span>:null}</span>
                {ifOpen(item,level)}</li>
               
         ));

      };
      /*<li key={item.id}><Link  to={'/company/'+item.companies_id+'/'+item.id}>{item.name}</Link><ul>{getChildrenNav(item.children,level+1)}</ul></li>
               */ 
      let company=getCompany(props.match.params.companyId);
     
     
   //console.log("PROPSEI",props.match.params.companyId );
  


   // let companyData=getCorrectCompany(props.match.params.companyId);


   //console.log(companyData);
    console.log("uuuuuuu",user);
    return (<div className="side"><div className="side-content"><nav><ul>{getChildrenNav(company.folders)}</ul>
    
    {user.role===1?
    <div className="btn"><Link onClick={()=>closeFileInfo()} to={'/company/'+company.id+'/addfolder'}>Lisää kansio</Link><Link onClick={()=>closeFileInfo()}   to={'/upload/'+company.id+'/addfiles'}>Lisää tiedostoja</Link><Link to="/users">Käyttäjähallinta</Link></div>:null}</nav></div></div>);
   
}