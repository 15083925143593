import {React,useEffect,useState} from "react";

import { useStore } from "../store/useStore";
import axios from "axios";
import { config } from "../config";
import { BrowserRouter as Router, Switch, Route,Link } from "react-router-dom";
import { apiClient } from "../apiCall";
//import { BrowserRouter as Router, Switch, Route,Link } from "react-router-dom";
import AddUser from "./AddUser"

export const usersDefaultState = {
	
    open:false,
    addnew:false
};
export default function UserManagement(props) {
	const { state, dispatch } = useStore();

	const { users } = state;
    const [usersState, setUsersState] = useState({
		...usersDefaultState
		
    });
    
    const apiCall = apiClient(state, dispatch);
    const fetchUsers = () => {
        // dispatch({ type: "FETCH_LOAD" });
     
         apiCall.GET("users");
        };

    useEffect(() => {
        if(state.user.loggedIn && users.loading==false && users.items==null){
            
          
            fetchUsers();
        
       
        }
       
       
       }, [dispatch, state]);
       
       const closeAdd=()=>{

        setUsersState({open:false,addnew:false});
       
       };
       const open=(user)=>{
      
        //setBasketState({open:!usersState.open});
        
        setUsersState({open:user,addnew:false});
      };
     const showAddNew=()=>{
        setUsersState({open:false,addnew:true});
     }
     const userAdded=()=>{
       
        setUsersState({open:false,addnew:false});

     };
        const getCompanies=(comps)=>{

return comps.map((company) =>
   
<span>{company.name} </span>
);


        };
       if(users.loading==false && users.items!=null){




        
        let items=null;
      
            let usersdata = users.items.map((userItem) =>
   
            <div className={"item"+(userItem.active==1?" active":" notactive")}  onClick={()=>open(userItem)} key={userItem.id}><div className="inner"><strong>{userItem.name}</strong><p>{userItem.email}</p><p className="user_role">{userItem.role===1?"Ylläpitäjä":getCompanies(userItem.companies)}</p></div></div>
       );


            items=<div className="items-holder"><div className="items"><div className="item add" onClick={()=>showAddNew()}><div className="inner">Lisää uusi</div></div>{usersdata}</div></div>
        
       


       


        return (<div className="users-holder"><div className="content"><div className={usersState.addnew || usersState.open?"users faint":"users"}>
          
            {items}</div></div>
            {usersState.addnew?<AddUser onClose={closeAdd}  userAdded={userAdded}/>:null}
            {usersState.open?<AddUser onClose={closeAdd} userAdded={userAdded} user={usersState.open}/>:null}
            </div>);

       }

    return (<div className="basket loading">Ladataan</div>);
    
     
   
    
    
   
}