import React,{useState,useEffect} from "react";

import { useStore } from "../store/useStore";

import { config } from "../config";
import { BrowserRouter as Router, Switch, Route,Link } from "react-router-dom";
import { apiClient } from "../apiCall";

import Errors from './Errors';
import axios from "axios";

export const addFilesDefaultState = {
	
    files:[],
    data:[],
    errors:[],
    loading:false
   
   
};

export default function FileUploader(props) {
	const { state, dispatch } = useStore();

  const apiCall = apiClient(state, dispatch);

    const { companies } = state;
	const [addFilesState, setaddFilesState] = useState({
		...addFilesDefaultState
		
    });
    const addFolder=(e)=>{

      if(e.currentTarget.value=="0"){

        return false;
      }
      const newFiles={...addFilesState};
      const index=parseInt(e.currentTarget.getAttribute("data-index"));
   
      const mod=newFiles.data[index];
    
      let selectedindex = e.nativeEvent.target.selectedIndex;
      let label = e.nativeEvent.target[selectedindex].text;
    
    
    
      mod.folders=[... mod.folders,{id:e.currentTarget.value,name:label}];
      newFiles.data[index]=mod;
    
    
     setaddFilesState({ ...newFiles });
    };
    useEffect(() => {
     
      if(addFilesState.loading){
       
          if(addFilesState.files.length>0){
           
            uploadFiles();
          }else{

            
            setaddFilesState({...addFilesState,loading:false});
            apiCall.GET("companies");
          }
      }
   }, [addFilesState.files]);

    const removeSelectedFolder=(fileindex,index)=>{

      const newFiles={...addFilesState};
     
      const mod=newFiles.data[fileindex];
      let f=[...mod.folders];
       f.splice(index,1);
       mod.folders=f;

       newFiles.data[fileindex]=mod;
    
     
       setaddFilesState({ ...newFiles });

    
      
    };





    
   const getCompany = (companyId) => {

       
        for(var i=0;i<companies.companies.length;i++){
            
             if(companies.companies[i].id==companyId){
 
                 return companies.companies[i];
             }
         } 
 
         return null;
        
       };
       

       const getFlat=(folders,t)=>{

            for(var i=0;i<folders.length;i++){

                t.push({folder:folders[i]});

                if(folders[i].children.length!=0){

                    getFlat(folders[i].children,t);
                }
            }
            return t;
    };
   
    const getOptionsNav=(items)=>{

        let t=[];
       const flat= getFlat(items,t);
                return flat.map((item) =>
   
                  


                 <option key={item.folder.id} value={item.folder.id}>{"-".repeat(item.folder.depth)}{item.folder.name}</option>
               
         );

      };
    let company=getCompany(props.match.params.companyId);
    const options=getOptionsNav(company.folders);



      const getFolderSelectors=(companies,file_index)=>{

       const selectors=companies.map((item)=>
          
         <select onChange={addFolder} data-index={file_index}><option value='0'>--  {item.name}   --</option>{getOptionsNav(item.folders)}</select>
       );

        return (<div className="folder-selectors">{selectors}</div>);
      };


      





     const button_class="";
      const onFileChange=(e)=>{

        var datat=[];
        var files=[];
        for(var i=0;i<e.target.files.length;i++){
         

          datat.push({name:e.target.files[i].name,ean:"",description:"",folders:[]});
          console.log("KUVIAA",e.target.files.length);
          files.push(e.target.files[i]);
       
        }
        console.log(e.target.files);
        setaddFilesState({ files:files,data:datat,loading:false,errors:[]});




      };
   


const  changeText=(e)=>{

  const newFiles={...addFilesState};
  const index=parseInt(e.currentTarget.getAttribute("data-index"));
  const name=e.currentTarget.getAttribute("name");
  const mod=newFiles.data[index];
  mod[name]=e.currentTarget.value;
  newFiles.data[index]=mod;


 setaddFilesState({ ...newFiles });
  //addFilesState.files[]

};


const folderSelector=(mainindex,folders)=>{

   return folders.map((folder,index) =>

      <span onClick={() =>removeSelectedFolder(mainindex,index)}>{folder.name}</span>
      
    );

};

console.log("addFilesState.files",addFilesState.files.length);

//(addFilesState.loading==false|| (addFilesState.loading && addFilesState.loadingIndex>index))
      const FILES =addFilesState.files.map((file,index) =>

    (true?
<div className="row">

<div className="col file-col">
<h3>#{(index+1)}</h3>
<div className='fields left'>
<label>Nimi
<input onChange={changeText} name='name' data-index={index} placeholder="Nimi" value={addFilesState.data[index].name}/>
</label>
<label>Ean <input onChange={changeText} name='ean' data-index={index} placeholder="Ean" value={addFilesState.data[index].ean}/>
</label>
<label>Kuvaus
<textarea onChange={changeText} name='description' data-index={index} placeholder="Kuvaus" value={addFilesState.data[index].description}/>
</label>
</div>

<div className="fields selectors">
<h5>Valitse kansiot</h5>
<div>{getFolderSelectors(companies.companies,index)}</div>
<div className="selected-folders"> {folderSelector(index,addFilesState.data[index].folders)}
          </div>
          </div></div></div>:"")
    );

      //<select onChange={addFolder} data-index={index}><option value='0'>--Valitse--</option>{options}</select>
  
    
  
   

console.log(options);


     const  uploadFiles=(wished_index)=>{

    
      wished_index=0;
      setaddFilesState({...addFilesState,loading:true,errors:[]});


      console.log("STATE INDEX:",addFilesState.loading);
     /// let loadIndex=0;
      let index=0;
        const formData = new FormData();
    
        // Update the formData object
        //for(var index=0;index< addFilesState.files.length;index++){
        //  setaddFilesState({...addFilesState,loading:true});
          let loadIndex=wished_index;
     
         
            formData.append(
              "file["+index+"]",
              addFilesState.files[loadIndex]
            );
          
            formData.append("data["+index+"][name]",addFilesState.data[loadIndex].name);
            formData.append("data["+index+"][ean]",addFilesState.data[loadIndex].ean);
            formData.append("data["+index+"][description]",addFilesState.data[loadIndex].description);
            for(var i=0;i<addFilesState.data[index].folders.length;i++){
              
              formData.append("data["+index+"][folders][]",addFilesState.data[loadIndex].folders[i].id);
           }
       // }
    
        apiCall.ADD("upload", formData,function(status,err_msgs=null){
          if(status==400){
           
            let a=[];

            console.log("sate error",addFilesState);

            setaddFilesState({...addFilesState,loading:false,errors:err_msgs});
           
           // setFolderData({...FolderData,errors:err_msgs})
        }else if(status==200){
         
       //  console.log({...addFilesState,files:[...addFilesState.files.shift()],data:[...addFilesState.data.shift()]});
        //  setaddFilesState({...addFilesState,files:[...addFilesState.files.shift()],data:[...addFilesState.data.shift()]});

          // setaddFilesState({...addFilesDefaultState});
          //setaddFilesState({...addFilesState,loading:true});
         
        
          //alert("OK"+loadIndex);
          console.log("FLIPPAAAAAA");
          let files=[...addFilesState.files];
          files.shift();
          let datat=[...addFilesState.data];
          datat.shift();
          console.log(addFilesState.files.shift(),addFilesState.files);
          console.log("SAIN VASTAUKSEN, wished oli",wished_index);


          setaddFilesState({...addFilesState,loading:true,files:[...files],data:[...datat]});

          /*if(files.length!=0){

            uploadFiles();
          }*/

         // console.log("PYYSIN",loadIndex+1,addFilesState.files.length);
        //if(wished_index+1<addFilesState.files.length){
            
            // uploadFiles(wished_index+1);
            
         //  uploadFiles(null);
       /// }
          //setaddFilesState
         //  apiCall.GET("companies");



        }

    }).then(() => {
        //apiCall.GET('destinations');
        
    });

  };


    console.log(FILES);
    console.log(state);
      console.log(addFilesState);
    return (<div className="folder-browswer filesadd">
      
      <div className="content">
        <h1>Lataa tiedostoja</h1>
        {addFilesState.errors?<Errors msgs={addFilesState.errors}/>:null}
        {addFilesState.files.length===0?
        <div className="file-selector">
            <input type="file" onChange={onFileChange} multiple/>
    </div>:null}

      {FILES}
   
      {addFilesState.files.length!==0 && addFilesState.loading===false?
    <div className="button-holder"><button className={button_class} onClick={()=>uploadFiles(0)}>Lataa tiedostot</button></div>:null}</div></div>
    
    );
   
}