import React,{useState} from "react";

import { useStore } from "../store/useStore";

import { config } from "../config";
import { BrowserRouter as Router, Switch, Route,Link } from "react-router-dom";
import { apiClient } from "../apiCall";
import { isDOMComponentElement } from "react-dom/test-utils";
import { getDefaultNormalizer } from "@testing-library/react";
import Errors from './Errors';

export const defaultFolderData = {
	name: '',
    parent:0,
    errors:[]
};

export default function AddFolder(props) {
	const { state, dispatch } = useStore();

	const { companies } = state;
    const [FolderData, setFolderData] = useState({
		...defaultFolderData
		
    });
    const apiCall = apiClient(state, dispatch);

    const getCompany = (companyId) => {

       
       for(var i=0;i<companies.companies.length;i++){
           
            if(companies.companies[i].id==companyId){

                return companies.companies[i];
            }
        } 

        return null;
       
      };

     
      
      let company=getCompany(props.match.params.companyId);
     
     
   //console.log("PROPSEI",props.match.params.companyId );
  


   // let companyData=getCorrectCompany(props.match.params.companyId);


   //console.log(companyData);
     const changeText=(e)=>{

      
        setFolderData({...FolderData,[e.currentTarget.name]:e.currentTarget.value})

     };

     const handleSubmit=(e)=>{
        let data={...FolderData};

        let url="companies/"+props.match.params.companyId+"/addfolder";
        apiCall.ADD(url, data,function(status,err_msgs=null){

            if(status==400){

                setFolderData({...FolderData,errors:err_msgs})
            }else if(status==200){


                setFolderData({...defaultFolderData});

                apiCall.GET("companies");
            }

        }).then(() => {
            //apiCall.GET('destinations');
            
        });


     };
     const changeSelect=(e)=>{

      
        setFolderData({...FolderData,[e.currentTarget.name]:e.currentTarget.value})

     };
     const getFlat=(folders,t)=>{

            for(var i=0;i<folders.length;i++){

                t.push({folder:folders[i]});

                if(folders[i].children.length!=0){

                    getFlat(folders[i].children,t);
                }
            }
            return t;
     };
     const getOptionsNav=(items)=>{

        let t=[];
       const flat= getFlat(items,t);




        return flat.map((item) =>
   
                  


                 <option key={item.folder.id} value={item.folder.id}>{"-".repeat(item.folder.depth)}{item.folder.name}</option>
               
         );

      };
      
    
      const options=getOptionsNav(company.folders);
      
     const button_class="";
     console.log("VALUE",FolderData);   
    return (<div className="side-editor">
        <Errors msgs={FolderData.errors}/>
        
        <div className="inner">
        <label>Kansion nimi<input onChange={changeText} name='name' value={FolderData.name}/></label><select name="parent"  onChange={changeSelect} value={FolderData.parent}><option value="0">--lisää päätasoon--</option>{options}</select>
        <div className="button-holder">
        <button onClick={handleSubmit} className={button_class}>Luo kansio</button>
        </div>
        </div>
        </div>);
   
}