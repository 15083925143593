import {React,useState}  from "react";

import { useStore } from "../store/useStore";
import axios from "axios";
import { config } from "../config";
import { BrowserRouter as Router, Switch, Route,Link } from "react-router-dom";

import FileInfo from "./FileInfo";
 
export const folderbrowserDefaultState = {
	
  open:false
};
export default function FolderBrowser(props) {
	const { state, dispatch } = useStore();

	const { companies,common } = state;


  const [folderbrowserState, setfolderbrowserState] = useState({
		...folderbrowserDefaultState
		
    });
   const getFolderPath=(folder_id)=>{

    console.log("companiescompaniescompaniescompanies",companies);
    // getFolder(folders[i].children,folderId);
    for(var i=0;i<companies.companies.length;i++){

      let result=getCompanyFolder(companies.companies[i].id,folder_id)
      console.log("etsin",result,folder_id);

      if(result){

        let path=[];
        let url_base="/company/"+companies.companies[i].id;
        path.push([url_base+"/"+result.id,result.name]);
        while(result && result.folders_parent_id!=0){

          
           result=getCompanyFolder(companies.companies[i].id,result.folders_parent_id);
           if(result){
             //url+="/"+result.id;
           path.push([url_base+"/"+result.id,result.name]);
           }

        }

        return path.reverse();
          

      }


    }

return null;

   }
    const getFolder=(folders,folderId)=>{

      
        for(var i=0;i<folders.length;i++){
           
                if(folders[i].id==folderId){

                    return folders[i];
                }else{


                    let is=getFolder(folders[i].children,folderId);

                    if(is){

                        return is;
                    }

                }
        }

        return null;

    };
    const getCompanyFromId = (companyId) => {

      
      for(var i=0;i<companies.companies.length;i++){
          
           if(companies.companies[i].id==companyId){

                 

                 return companies.companies[i];


           }
       } 

       return null;
      
     };


    const getCompanyFolder = (companyId,folderId) => {

      
       for(var i=0;i<companies.companies.length;i++){
           
            if(companies.companies[i].id==companyId){

                  

                  return getFolder(companies.companies[i].folders,folderId);


            }
        } 

        return null;
       
      };

      const getChildrenNav=(items)=>{

        
        return items.map((item) =>
   
                 <li key={item.id}><Link  to={'/company/'+item.companies_id+'/'+item.id}>{item.name}</Link><ul className="children">{getChildrenNav(item.children)}</ul></li>
               
         );

      };
      const openFile=(item)=>{
      
       // setfolderbrowserState({open:itemid});
       dispatch({ type: "OPENFILEINFO", file: item });
      };



      if(props.match.params.companyId==undefined){

        const comp=companies.companies.map((company) =>
        
            

        <div className="item folder" key={("c"+company.id)} ><div className="inner">
          <Link to={("/company/"+company.id)}>
           <div className="image-holder"><div className="image"><img src={config.apiUrl+"/images/icons/folder"} /></div></div>  
           <div className="text"><h3>{company.name}</h3></div></Link></div></div>
   );
        return (<div className="folder-browswer">
        <div className="content"><div className="folder-header"><ul></ul></div>{comp}</div>
       
    </div>);
      }

    
      if(props.match.params.folderId==undefined){

        let companyobj= getCompanyFromId(props.match.params.companyId);
        const folderItemsMail = companyobj.folders.map((folder) =>
        
            

        <div className="item folder" key={("f"+folder.id)} ><div className="inner">
          <Link to={("/company/"+folder.companies_id+"/"+folder.id)}>
           <div className="image-holder"><div className="image"><img loading="lazy" src={config.apiUrl+"/images/icons/folder"} /></div></div>  
           <div className="text"><h3>{folder.name}</h3></div></Link></div></div>
   );


        return (<div className="folder-browswer">
        <div className="content"><div className="folder-header"><ul></ul></div>{folderItemsMail}</div>
       
    </div>);
      }







      let folder=getCompanyFolder(props.match.params.companyId, props.match.params.folderId);


     

      if(folder){
        console.log(folder.files,config.apiUrl);

        const folderItems = folder.children.map((folder) =>
        
            

             <div className="item folder" key={("f"+folder.id)} ><div className="inner">
               <Link to={("/company/"+folder.companies_id+"/"+folder.id)}>
                <div className="image-holder"><div className="image"><img loading="lazy" src={config.apiUrl+"/images/icons/folder"} /></div></div>  
                <div className="text"><h3>{folder.name}</h3></div></Link></div></div>
        );
     

        const path=getFolderPath(props.match.params.folderId);

        const foldernav=path.map((path)=>
          <li><Link  to={path[0]}>{path[1]}</Link></li>
        );


        const listItems = folder.files.map((file) =>
        
            

             <div className={common.openfile && file.id==common.openfile.id?"item active":"item"} key={file.id} onClick={()=>openFile(file)}><div className="inner">
                <div className="image-holder"><div className="image"><img loading="lazy" src={config.apiUrl+""+file.thumb} /></div></div>  
                <div className="text"><h3>{file.name}</h3></div></div></div>
        );
     
        console.log("HÄAHÄAHÄAHÄAAHÄAHÄAHÄHÄAHAÄAHÄHAÄHAHÄAHAÄ");




        return (
        <div className="folder-browswer">
            <div className="content"><div className="folder-header"><ul>{foldernav}</ul></div>{folderItems}{listItems}</div>
           
        </div>);
      }else{
        return (<div className="folder-browswer">NO CONTENT</div>);

      }
     
   
    
    
   
}