import {React,useState} from "react";
import logo from '../logos/duell.svg';
import { useStore } from "../store/useStore";
import axios from "axios";
import { config } from "../config";
import { BrowserRouter as Router, Switch, Route,Link } from "react-router-dom";
import Basket from "./Basket";
import { useLocation } from 'react-router-dom';

export const HeaderStateDefaultState = {
	
    term:null
    
  };

  export const QueryStateDefaultState = {
	
    files:null,
    term:null
    
  };


export default function Header(props) {
	const { state, dispatch } = useStore();

	const { companies,user,common } = state;

    const location = useLocation();  

    const axiosConf = {};
    const [HeaderState, setHeaderState] = useState({
		...HeaderStateDefaultState
		
    });
    const [QueryState, setQueryState] = useState({
		...QueryStateDefaultState
		
    });
   const axiosClient = axios.create(axiosConf);



    const runQuery=(term)=>{

        var data={term:term}
        axiosClient({
            method: "post",
            headers: { Authorization: "Bearer "+user.token },

            url: config.apiUrl + "/search",
            data
        })
            .then((response) => {
               
                    if(response.data){
                        
                        setQueryState({...response.data});

                    }


            })
            .catch((error) => {
                Promise.reject(error);
            });





    }
   
    const logout=()=>{

      dispatch({ type: "LOGOUT" });


    };
  
     const listItems = companies.companies.map((item) =>
   
    <li key={item.id}><Link  className={((location.pathname.split("/").length>=3 && parseInt(location.pathname.split("/")[2])===item.id)?"active":"")} onClick={()=>closeInfos()}  to={'/company/'+item.id}>{item.name}</Link></li>
     );
     
     const openFile=(item,clear=false)=>{
      
        // setfolderbrowserState({open:itemid});
        if(clear){
        setQueryState({term:null,files:null});
        setHeaderState({term:null});
        }
        dispatch({ type: "OPENFILEINFO", file: item });


       };
 
       const closeInfos=()=>{
        dispatch({ type: "OPENFILEINFO", file: null });
       

      };
      const closeSearch=()=>{

        setQueryState({term:null,files:null});
        setHeaderState({term:null});

      };
      const closeFileInfoAndSearch=()=>{

        dispatch({ type: "OPENFILEINFO", file: null });


      };
      const closeFileInfo=()=>{

        dispatch({ type: "OPENFILEINFO", file: null });


      };
    const changeSearchTerm=(e)=>{

      
        setHeaderState({...HeaderState,term:e.currentTarget.value});

       
        runQuery(e.currentTarget.value);

     };
     console.log("H",QueryState);

     let results=null;
     let largelist=null;
     let largebrowser=null;
     if(QueryState.files && location.pathname!=="/haku"){

    
        let filesit= QueryState.files;

        let count= QueryState.files.length;
        if(filesit.length>5){
            filesit= QueryState.files.slice(0,5);
        }
       let lis= filesit.map((file,index)=>
            <li key={index}><div className="smallfile" onClick={()=>openFile(file,true)}><div className="image"><img loading="lazy"  src={config.apiUrl+""+file.thumb} /></div>{file.name}</div></li>
        );

        console.log("Qs",QueryState)
        if(QueryState.term!==null){
          if(filesit.length==0){
            lis=<li className="no-result">Ei hakutuloksia</li>;
          }

        results=<ul>{lis}<li className="to-search"><Link onClick={()=>closeFileInfo()} to="/haku">Avaa hakusivu ({count})</Link><li onClick={()=>closeSearch()} className="close">Sulje</li></li></ul>
        }

     }else{

        if(QueryState.files && location.pathname==="/haku"){


            let largelist = QueryState.files.map((file) =>
        
            

             <div className={common.openfile && file.id==common.openfile.id?"item active":"item"} key={file.id} onClick={()=>openFile(file)}><div className="inner">
                <div className="image-holder"><div className="image"><img loading="lazy" src={config.apiUrl+""+file.thumb} /></div></div>  
                <div className="text"><h3>{file.name}</h3></div></div></div>
        );


        largebrowser=<div className="folder-browswer search-results"><div className="content">{largelist}</div></div>

        }



     }



    const search=<li className="search-item"><div className="search-holder"><input type="text" onChange={changeSearchTerm} value={HeaderState.term?HeaderState.term:""}/></div>{results}</li>;


   const logoutjtml=<li className="logout" onClick={()=>logout()}>Logout</li>;
    return (<div><header><ul><li className="logo"><img loading="lazy" src={logo}/></li>{listItems}{search}{logoutjtml}<li className="basket-li"><Basket/></li></ul></header>{largebrowser}</div>);
    
}