export const commonFunctions = (state, dispatch) => {


    const companies=state.companies.companies;
console.log(companies);
    return {    
		

		getFolder: (id) => {

            const searchFolder=(id,item)=>{
               

                console.log("search",item.name);


                if(item.id==id){
                    console.log("HIT",item); 
                    return item;
                }else{
                   
                    for(var i=0;i<item.children.length;i++){
                        
                        let found=searchFolder(id,item.children[i]);


                        if(found!==false){
                            return found;
                        }

                    }

                }

                return false;

            };

          
           for(var i=0;i<companies.length;i++){

            console.log("tästä",companies[i])
                    for(var x=0;x<companies[i].folders.length;x++){

                        let found=searchFolder(id,companies[i].folders[x]);
                    if(found!==false){
                        return found;
                    }


                    }
                    
           }



           return false;


        }

    }
};