
import './App.scss';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useStore } from "./store/useStore";
import LoginForm from "./components/LoginForm";
import Header from "./components/Header";

import React, { useEffect, useState } from 'react';
import { apiClient } from "./apiCall";
import Sidenav from './components/Sidenav';
import FolderBrowser from './components/FolderBrowser';
import AddFolder from './components/AddFolder';
import EditFolder from './components/EditFolder';
import UserManagement from './components/UserManagement';
import AddUser from './components/AddUser';
import FullBasket from './components/FullBasket';

import FileUploader from './components/FileUploader';
import FileInfo from './components/FileInfo';
function App() {

  const { state, dispatch } = useStore();

const apiCall = apiClient(state, dispatch);



const fetchCompanies = () => {
 // dispatch({ type: "FETCH_LOAD" });

  apiCall.GET("companies");
};

useEffect(() => {
 if(state.user.loggedIn && state.companies.firstLoad==false){
console.log("PYYSIN", state.companies.loading);
  fetchCompanies();

 }


}, [dispatch, state]);


  if (state.user.loggedIn==false){

    return (<LoginForm/>);

  }else{

    console.log("STATE C LOADING",state.companies.mainLoader);
    if (state.companies.mainLoader) {

      return (<div>ladataan..</div>)
      

    }




  return (
    <div className="App"><Router>
        <Header/>
        <div className="main-content">

        <Route exact path="/basket"  component={FullBasket}></Route>
           <Route exact path="/company/:companyId/:folderId?/:mode?"  component={Sidenav}></Route>

           <Route exact path="/company/:companyId/addfolder"  component={AddFolder}></Route>
           <Route exact path="/upload/:companyId/addfiles"  component={FileUploader}></Route>
           <Route exact path="/company/:companyId/:folderId/editfolder"  component={EditFolder}></Route>

           <Route exact path="/company/:companyId?/:folderId?/:mode?"  component={FolderBrowser}></Route>
           <Route exact path="/"  component={FolderBrowser}></Route>

          

           <Route exact path="/users"  component={UserManagement}></Route>
           <Route exact path="/users/add"  component={AddUser}></Route>
           {state.common.openfile?<FileInfo/>:null}
           </div>
       
      </Router>
     
    </div>
  );
  }
}

export default App;
