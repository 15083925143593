import React from "react";

import { useStore } from "../store/useStore";
import axios from "axios";
import { config } from "../config";
import { BrowserRouter as Router, Switch, Route,Link } from "react-router-dom";

export default function Errors(props) {
	const { state, dispatch } = useStore();

	
    

  
    /* const listItems = companies.companies.map((item) =>
   
    <li key={item.id}><Link to={'/company/'+item.id}>{item.name}</Link></li>
     );
     */
   console.log(props.msgs);

   
    var texts=[];
    for(var i in props.msgs){

        texts.push(props.msgs[i][0]);
    }
    const errors=texts.map((err,index) =>
   
            <p key={index}>{err}</p>
     );


    return (<div className="errors">{errors}</div>);
    return (<div></div>);
}