import {React,useEffect,useState} from "react";

import { useStore } from "../store/useStore";
import axios from "axios";
import { config } from "../config";
import { BrowserRouter as Router, Switch, Route,Link } from "react-router-dom";
import { apiClient } from "../apiCall";


export const basketDefaultState = {
	
    open:false
};
export default function Basket(props) {
	const { state, dispatch } = useStore();

	const { basket } = state;
    const [basketState, setBasketState] = useState({
		...basketDefaultState
		
    });
    
    const apiCall = apiClient(state, dispatch);
    const fetchBasket = () => {
        // dispatch({ type: "FETCH_LOAD" });
     
         apiCall.GET("basket");
        };

    useEffect(() => {
        if(state.user.loggedIn && basket.loading==false && basket.items==null){
            
            
                fetchBasket();
        
       
        }
       
       
       }, [dispatch, state]);
       
       const openClose=(item)=>{
        dispatch({ type: "OPENFILEINFO", file: null });
        setBasketState({open:!basketState.open});

      };
      const closeBasket=(item)=>{

        setBasketState({open:false});
      };

      const loadBasket=(item)=>{


            apiCall.ADD("basketlink",function(status,err_msgs=null){


              if(status==400){

             
            }else if(status==200){


              
alert("OK");
               
            }

        }).then(() => {
            //apiCall.GET('destinations');
            
        });


            /* apiCall.ADD(url, data,function(status,err_msgs=null){

            if(status==400){

                setFolderData({...FolderData,errors:err_msgs})
            }else if(status==200){


                setFolderData({...defaultFolderData});

                apiCall.GET("companies");
            }

        }).then(() => {
            //apiCall.GET('destinations');
            
        });*/ 

      };
      console.log("bastate",basketState);
console.log("basket basket",basket);
       if(basket.loading==false && basket.items!=null){


        let items=null;
        if(basketState.open){

            
            let basketdata = basket.items.content.map((basketItem) =>
   
            /*<div key={basketItem.id}>{basketItem.file.name}</div>*/
            <div className="smallfile"><div className="image"><img src={config.apiUrl+""+basketItem.file.thumb} /></div>{basketItem.file.name}</div>



       );

            items=<div className="items-holder"><div className="items"><div><Link onClick={closeBasket} to="/basket">Tarkasta paketti</Link><a href={basket.items.url} target="_blank" rel="noreferrer" >Lataa paketti</a></div></div></div>
        
        }


        console.log("basketbasketbasketbasketbasket",basket);


        return (<div className="basket-holder"><div className="basket" onClick={()=>openClose()}>
            
            <span>{basket.items.content.length}</span>{basket.adding?"lisätään":null} Latauspaketti</div>{items}</div>);

       }

    return (<div className="basket loading">Ladataan</div>);
    
     
   
    
    
   
}