import React,{useState,useEffect} from "react";

import { useStore } from "../store/useStore";

import { config } from "../config";
import { BrowserRouter as Router, Switch, Route,Link } from "react-router-dom";
import { apiClient } from "../apiCall";
import { isDOMComponentElement } from "react-dom/test-utils";
import { getDefaultNormalizer } from "@testing-library/react";
import Errors from './Errors';

export const defaultUserData = {

	name: '',
    email:'',
    title:'',
    role:0,
    active:1,
    companies:[],
    errors:[]
};

export default function AddUser(props) {
	const { state, dispatch } = useStore();

	const { companies } = state;
    const [UserData, setUserData] = useState({
		...defaultUserData
		
    });
    useEffect(() => {

        if(props.user){

            console.log(props.user);
           
            setUserData({active:props.user.active,name:props.user.name,email:props.user.email,title:props.user.title,role:props.user.role,companies: props.user.companies.map(item => item.id),errors:[]});
        
           }


    }, []);

   /*if(props.user){

    setUserData({...props.user});

   }*/ 



    const apiCall = apiClient(state, dispatch);
   
  
     
   //console.log("PROPSEI",props.match.params.companyId );
  


   // let companyData=getCorrectCompany(props.match.params.companyId);


   //console.log(companyData);
     const changeText=(e)=>{

      
        setUserData({...UserData,[e.currentTarget.name]:e.currentTarget.value})

     };

     const handleSubmit=(e)=>{
        let data={...UserData};
          if(props.user){

        
          
           
            apiCall.EDIT("users",props.user.id, data,function(status,err_msgs=null){

                if(status==400){
    
                    setUserData({...UserData,errors:err_msgs})
                }else if(status==200){
    
                   
                    props.userAdded();
                    apiCall.GET("users");
                }
    
            }).then(() => {
                //apiCall.GET('destinations');
                
            });

            


          }else{
         
        apiCall.ADD("adduser", data,function(status,err_msgs=null){
            console.log(status,err_msgs);
            if(status==400){
               
                setUserData({...UserData,errors:err_msgs})
            }else if(status==200){

               
                props.userAdded();
                apiCall.GET("users");
            }

        }).then(() => {
            //apiCall.GET('destinations');
            
        });



    }


     };
     const changeRadio=(e)=>{

      
        setUserData({...UserData,[e.currentTarget.name]:parseInt(e.currentTarget.value)})

     };
     const changeActive=(e)=>{

       
        setUserData({...UserData,[e.currentTarget.name]:(e.currentTarget.checked?1:0)})

     };
     const closeMe=(e)=>{

        props.onClose();
     }
     const companyClick=(id)=>{
console.log("CLICK");
        if(UserData.companies.some(val=>val==id)){ 

            var comps = [...UserData.companies];
            var index = comps.indexOf(id);
            if (index !== -1) {
                comps.splice(index, 1);
                setUserData({...UserData,companies:comps});
            }



        }else{

            setUserData({...UserData,companies:UserData.companies.concat(id)});


        }

     }
     
     const getOptionsNav=(items)=>{

        let t=[];
       //const flat= getFlat(items,t);


        console.log("CO",UserData.companies);

        return items.map((item) =>
   
                  


                <label  key={item.id} ><input checked={UserData.companies.some(val=>val==item.id)?true:false} onChange={()=>companyClick(item.id)} type="checkbox" value={item.id}/>{item.name}</label>
               
         );

      };
      console.log("errorr",UserData.errors);
    
      const companyselector=getOptionsNav(companies.companies);
     
     const button_class="";
      
    return (<div className="side-editor side-fileinfo"><div className="inner"><h3>{props.user?"Muokkaa käyttäjää":"Uusi käyttäjä"}</h3><Errors msgs={UserData.errors}/>
    <label>Nimi
    <input onChange={changeText} name='name' value={UserData.name}/>
    </label>
    <label>Email
    <input onChange={changeText} name='email' value={UserData.email}/>
    </label>
    <label>Titteli
    <input onChange={changeText} name='title' value={UserData.title}/>
    </label>
    <div className="radio">
         <h4>Rooli</h4>
        <label><input type="radio" name="role" onChange={changeRadio} checked={UserData.role==0?true:false} value="0"/> Perus</label>
        <label><input type="radio" name="role" onChange={changeRadio} checked={UserData.role==1?true:false} value="1"/> Ylläpitäjä</label>

    </div>
      {UserData.role!==1?
    <div className="companies-user">
        <h4>Valitse yritykset</h4>
        {companyselector}

    </div>:null}
    <div className="isactive">
    <h4>Status</h4>
    <label><input type="checkbox" name="active" onChange={changeActive} checked={UserData.active==1?true:false} value="1"/> Aktiviinen</label>

    </div>
    
    <div className="button-holder">
      
    <button onClick={handleSubmit} className={button_class}>Tallenna</button>
    </div>
    {props.onClose?<div  onClick={closeMe}  className="close-me">sulje</div>:null}
    </div></div>);
   
  
}